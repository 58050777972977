const isLocalhost = (url: URL) => {
  const hostname = url.hostname
  return (
    hostname === 'localhost' ||
    !hostname.includes('.') ||
    hostname.endsWith('.localhost') ||
    hostname.endsWith('.local.test') ||
    hostname.endsWith('.local') ||
    url.port === '3001'
  )
}

export const getApiHost = (org: string) => {
  if (isLocalhost(new URL(window.location.toString()))) {
    return `http://${window.location.hostname}:3000`
  }
  if (window.location.hostname.includes('.dev.cluster.gpt.liip.ch')) {
    return `https://${org}.api.dev.cluster.gpt.liip.ch`
  }
  if (window.location.hostname.includes('.prod.cluster.gpt.liip.ch')) {
    return `https://${org}.api.prod.cluster.gpt.liip.ch`
  }
  if (window.location.hostname.includes('.dev.genai.liip.ch')) {
    return `https://${org}.api.dev.genai.liip.ch`
  }
  if (window.location.hostname.includes('.prod.genai.liip.ch')) {
    return `https://${org}.api.prod.genai.liip.ch`
  }
  return `https://${org}-api.gpt.liip.ch`
}
