import './assets/css/settingsLS.css'
import './assets/css/reset.css'
import './assets/css/base.css'
import './i18n.ts'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { GlobalContextProvider } from './components/GlobalContext.tsx'
import { LoadingView } from './components/LoadingView.tsx'
import ErrorPage from './ErrorPage.tsx'

// eslint-disable-next-line react-refresh/only-export-components
const Privacy = React.lazy(() => import('./components/Privacy.tsx'))
// eslint-disable-next-line react-refresh/only-export-components
const Bot = React.lazy(() => import('./components/Bot.tsx'))

// eslint-disable-next-line react-refresh/only-export-components
const App = React.lazy(() => import('./App.tsx'))
const router = createBrowserRouter([
  { path: '/privacy', element: <Privacy /> },
  { path: '/bot', element: <Bot /> },

  {
    path: '/*',
    element: <App />,
    errorElement: <ErrorPage />,
  },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <GlobalContextProvider>
      <Suspense fallback={<LoadingView />}>
        <RouterProvider router={router} />
      </Suspense>
    </GlobalContextProvider>
  </React.StrictMode>,
)
